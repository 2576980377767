import React, { useState, useEffect } from "react";
import { clienteAxiosMobile } from "../Utils/axios.js";
import { clienteAxios } from "../Utils/axios.js";
import "./Productos.css";
import MenuProductos from "./MenuProductos";
import Clasificacion from "./Clasificacion.js";
const Productos = () => {
  const [wooProductos, guardarWooProductos] = useState([]);
  const [wooCategorias, guardarWooCategorias] = useState([]);
  const [wooEtiquetas, guardarWooEtiquetas] = useState([]);

  const userName = process.env.REACT_APP_USERNAME;
  const pass = process.env.REACT_APP_PASS;
  const token = Buffer.from(`${userName}:${pass}`, 'utf8').toString('base64');
  const urlActual = window.location.pathname;
  console.log(urlActual);

  const idioma = urlActual.substring(1,3);

  console.log(urlActual);

  useEffect(() => {
    const obtenerMenuWOO = async () => {
      await clienteAxios("wp-json/wc/v3/products?per_page=50", {
        headers: {
          Authorization: `Basic ${token}`
        }
      }).then((respuesta) => {

        let productosIdioma = respuesta.data;
        if(idioma == "en"){
           //productosIdioma = productosIdioma.filter(producto => {
             // producto.categories[0].id != 616
             //let obj = {id:616}
             //console.log("POI",producto => producto.categories.filter(cat=>cat.id == obj.id))
             //console.log("PID",productosIdioma.filter(producto => producto.categories.filter(cat=>cat.id == obj.id) != 616))
           //})
           let obj = {id:616}
          productosIdioma = productosIdioma.filter(producto => producto.categories.filter(cat=>cat.id == obj.id) != 616)
          //console.log("Idioma ",idioma," PII ",productosIdioma)
        }else{
          //productosIdioma = productosIdioma.filter(producto => {
            // producto.categories[0].id != 616
            //let obj = {id:743}
            //console.log("PID",productosIdioma.filter(producto => producto.categories.filter(cat=>cat.id == obj.id) != 743))
          //})
          let obj = {id:743}
          productosIdioma = productosIdioma.filter(producto => producto.categories.filter(cat=>cat.id == obj.id) != 743)
          console.log("Idioma ",idioma," PIE ",productosIdioma)
        }

        guardarWooProductos(productosIdioma);
      })
        .catch((error) => {
          console.log(error);
        });
    };
    obtenerMenuWOO();

    const obtenerCategoriasWOO = async () => {
      await clienteAxios("wp-json/wc/v3/products/categories?orderby=id&order=asc", {
        headers: {
          Authorization: `Basic ${token}`
        }
      }).then((respuesta) => {
        let omitirCategoria = respuesta.data;
        if (idioma == "en"){
          omitirCategoria = omitirCategoria.filter(categoria => categoria.id != 743);
          omitirCategoria = omitirCategoria.filter(categoria => categoria.idioma[0] == "en")
        }else{
          omitirCategoria = omitirCategoria.filter(categoria => categoria.id != 616);
          omitirCategoria = omitirCategoria.filter(categoria => categoria.idioma[0] != "en")
        }
        guardarWooCategorias(omitirCategoria);
      })
        .catch((error) => {
          console.log(error);
        });
    };
    obtenerCategoriasWOO();

    const obtenerTagsWOO = async () => {
      await clienteAxios("wp-json/wc/v3/products/tags?per_page=50", {
        headers: {
          Authorization: `Basic ${token}`
        }
      }).then((respuesta) => {
        let tagsIngles
        if (idioma == "en")
          tagsIngles = respuesta.data.filter(tag => tag.idioma[0] == "en")
        else
          tagsIngles = respuesta.data.filter(tag => tag.idioma[0] != "en")
        guardarWooEtiquetas(tagsIngles);
      })
        .catch((error) => {
          console.log(error);
        });
    };
    obtenerTagsWOO();

  }, []);

  return (
    <>

      {wooProductos.length > 0 ? (
        <Clasificacion
          productos={wooProductos}
          categorias={wooCategorias}
          etiquetas={wooEtiquetas}
        />
      ) : null


      }

    </>
  );
};

export default Productos;
